<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 24">
        <path
            fill-rule="nonzero"
            d="M11.9992014-.00039931 0 11.9998003 11.9992014 24l1.7649408-1.7649408L3.52888327 11.9998003 13.7641422 1.76454146z"
        />
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
