<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 24">
        <path
            fill-rule="nonzero"
            d="M1.7655406-.00039931 13.764742 11.9998003 1.7655406 24 .0005998 22.2350592l10.23525893-10.2352589L.0005998 1.76454146z"
        />
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
